import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../auth/auth.guard';
import { WorkerComponent } from './components/worker/worker.component';
import { WorkersListComponent } from './components/workers-list/workerslist.component';

const routes: Routes = [
  {
    path: '',
    component: WorkersListComponent,
    canActivate: [AuthGuard],
    // children: [
    //   {
    //     path: '',
    //     component: WorkersListComponent,
    //     data: {returnUrl: window.location.pathname},
    //   },
    //   {
    //     path: ':id/edit',
    //     component: WorkerComponent,
    //     data: {returnUrl: window.location.pathname},
    //     canActivate: [AuthGuard]
    //   },
      //
    // ]
  },
  {
    path: ':id/edit',
    component: WorkerComponent,
    data: {returnUrl: window.location.pathname},
    canActivate: [AuthGuard]
  },
  {path: '', redirectTo: 'workers', pathMatch: 'full'},
  {path: '**', redirectTo: '//workers', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkersRoutingModule { }
