import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SprintDetailComponent } from './components/sprint-detail/sprint-detail.component';
import { SprintEditComponent } from './components/sprint-edit/sprint-edit.component';
import { SprintListComponent } from './components/sprint-list/sprint-list.component';
import { SprintsformComponent } from './sprints.component';
import { SubmissionComponent } from './components/submissionFile/submission/submission.component';
import { TaskEditComponent } from './components/tasks/task-edit/task-edit.component';
import { TaskFormComponent } from './components/taskforms/taskform-detail/taskform-detail.component';
import { AuthGuard } from '../../auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: SprintsformComponent,
    children: [
      // {
      //   path: '',
      //   redirectTo: '',
      //   pathMatch: 'full'
      // },
      {
        path: '',
        component: SprintListComponent,
        data: {returnUrl: window.location.pathname},
        canActivate: [AuthGuard],
      },
      {
        path: ':id/edit',
        component: SprintEditComponent,
        data: {returnUrl: window.location.pathname},
        canActivate: [AuthGuard],
      },
      {
        path: ':id',
        component: SprintDetailComponent,
        data: {returnUrl: window.location.pathname},
        canActivate: [AuthGuard],
      },
      
      { 
        path: ':id/task/:task', 
        component: TaskEditComponent,
        data: {returnUrl: window.location.pathname},
        canActivate: [AuthGuard],
      },
      { 
        path: ':id/taskform/:form', 
        component: TaskFormComponent,
        data: {returnUrl: window.location.pathname},
        canActivate: [AuthGuard],
      },
      { 
        path: ':id/submission/:submission', 
        component: SubmissionComponent,
        data: {returnUrl: window.location.pathname},
        canActivate: [AuthGuard],
      },
      {path: '', redirectTo: 'sprints', pathMatch: 'full'},
      {path: '**', redirectTo: '//sprints', pathMatch: 'full'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SprintsformRoutingModule { }
