import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { map, tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import Amplify, { Auth } from 'aws-amplify';
import { environment } from './../../environments/environment';
import amplify from './../../aws-exports';
import { resolve } from 'path';
Amplify.configure(
  {
    aws_project_region: "eu-central-1",
  aws_content_delivery_bucket: "muhimma-portalfiles",
  aws_content_delivery_bucket_region: "eu-central-1",
  aws_content_delivery_url: "https://d2wt1z63mzf0az.cloudfront.net",
  aws_cognito_identity_pool_id:
    "eu-central-1:19e0bd7d-8e4f-4235-8788-278f4d0f8519",
  aws_cognito_region: "eu-central-1",
  aws_user_pools_id: "eu-central-1_pyr68DNuJ",
  aws_user_pools_web_client_id: "1voulc6eucgvncb2kg73u0n18",
  aws_cloud_logic_custom: [
    {
      name: "apiconfiguration",
      endpoint: "https://5cc8a8d24a.execute-api.us-east-2.amazonaws.com/Prod",
      region: "us-east-2",
      custom_header: async () => {
        return {
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        }
    }
  },
    {
      name: "api",
      endpoint: "https://iveushzemk.execute-api.me-south-1.amazonaws.com/dev/",
      // endpoint: "https://api.muhimmaplatform.com/node1-dev-v1/",
      // endpoint: "https://50c8dzjrw3.execute-api.me-south-1.amazonaws.com/dev/",
      // endpoint: "https://apis.muhimmaplatform.com/v1/",
      region: "eu-central-1",
      custom_header: async () => {
        return {
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        };

      }
    },
    {
      name: "geo",
      endpoint: "https://iveushzemk.execute-api.me-south-1.amazonaws.com/dev/geo",
      // endpoint: "https://50c8dzjrw3.execute-api.me-south-1.amazonaws.com/dev/geo",
      // endpoint: "https://apis.muhimmaplatform.com/v1/geo/",
      region: "1voulc6eucgvncb2kg73u0n18",
      custom_header: async () => {
        return {
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        };
      }
    },{
      name: 'bahrain',
      endpoint: "https://iveushzemk.execute-api.me-south-1.amazonaws.com/dev/",
      // endpoint: "https://50c8dzjrw3.execute-api.me-south-1.amazonaws.com/dev/",
      // endpoint: 'https://apis.muhimmaplatform.com/v1/',
      region:'me-south-1',
      custom_header: async () => {
        return {
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        };
      },
    },
    {
      name: "api_node_1",
      endpoint: environment.api_node_1,
    //   endpoint: "https://api.muhimmaplatform.com/node1-devt-v1/",
    //   endpoint: "https://api.muhimmaplatform.com/node1-dev-v1/",
      // endpoint: "https://api.muhimmaplatform.com/node1-prod-v1/",
      custom_header: async () => {
        return {
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        };

      }
    },
    {
      name: "api_node_2",
	  endpoint: environment.api_node_2,
    //   endpoint: "https://api.muhimmaplatform.com/node2-devt-v1/",
    //   endpoint: "https://api.muhimmaplatform.com/node2-dev-v1/",
      // endpoint: "https://api.muhimmaplatform.com/node2-prod-v1/",
      custom_header: async () => {
        return {
          Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()
        };

      }
    },
  ]
  });
@Injectable()
export class AuthService {

  public loggedIn: BehaviorSubject<boolean>;

  constructor(
    private router: Router
  ) {
   //Amplify.configure(amplify);
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  /** signup */
 // public signUp(email, password): Observable<any> {
  //  return fromPromise(Auth.signUp(email, password));
 // }
public SignInFederated(token: any, expires_at: any, user: any): Observable<any>  {
  return fromPromise(Auth.federatedSignIn(
    'facebook',
    { token, expires_at },
    user
  ));
}


public getUser(): Observable<any> {
return fromPromise(Auth.currentAuthenticatedUser()
  .then(user => {
    
    return user;
  })
  .then(data => console.log(data))
  .catch(err => console.log(err)));

}

public updateUserAttributes(user, attributes){
  return fromPromise(Auth.updateUserAttributes(user, attributes).then(data => console.log(data))
  .catch(err => console.log(err)));
}

public changepassword(user, oldpassword, newpassword){
  return fromPromise(Auth.changePassword(user, oldpassword, newpassword).then(data => console.log(data))
  .catch(err => console.log(err)));
}

  public signUp(emaill: string, passw0rd, mobile, birthDate, firstName, lastName, gender): Observable<any> {
    return fromPromise(
      Auth.signUp({
        username: emaill,
        password: passw0rd,
        attributes: {
          email: emaill,             // optional
          phone_number: mobile,
          gender: gender,
          birthdate: birthDate,
          given_name: firstName,
          family_name: lastName
          // Other custom attributes...
        },
        validationData: [],  // optional
        })
        .then(data => console.log(data))
        .catch(err => console.log(err)));

  }

  /** confirm code */
  public confirmSignUp(email, code): Observable<any> {
    return fromPromise(Auth.confirmSignUp(email, code));
  }

  /** signin */
  public signIn(email, password): Observable<any> {
    
    return fromPromise(Auth.signIn(email, password))
      .pipe(
        tap(() => this.loggedIn.next(true))
      );
  }


  public forgotPassword(email): Promise<any> {

    return new Promise(function(resolve, reject) {
      Auth.forgotPassword(email)
      .then(data => { console.log(data); resolve(data); } )
      .catch(err => { console.log(err); resolve(err); } )
      });
  }

  public changePassword(email, code, password): Promise<any>{
    return new Promise(function(resolve, reject) {
    Auth.forgotPasswordSubmit(email, code, password)
    .then(data => resolve(data))
    .catch(err => resolve(err))
    });
  }

  /** get authenticat state */
  public isAuthenticated(): Observable<boolean> {
    return fromPromise(Auth.currentAuthenticatedUser())
      .pipe(
        map(result => {
          this.loggedIn.next(true);
          return true;
        }),
        catchError(error => {
          this.loggedIn.next(false);
          return of(false);
        })
      );
  }

  /** signout */
  public signOut() {
    fromPromise(Auth.signOut())
      .subscribe(
        result => {
          this.loggedIn.next(false);
          localStorage.clear();
          this.router.navigate(['/login']);
        },
        error => console.log(error)
      );
  }// end signout
}
