import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import  { API } from 'aws-amplify';
import { ToastrService } from 'ngx-toastr';
import { Setting } from '../model/Setting';
@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(public loader: LoadingBarService, public toastr: ToastrService) { }
  public saveSetting(model: Setting) {
    const apiName = 'api';
    const path = 'settings/admin';
    const myInit = {
      body: model,
      headers: {}
    };
    this.loader.start();

    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          this.loader.complete();

          const data = response;
          if (data.response_code == 200) {
            resolve(data.data);
          }
          else
          {
            return this.toastr.error(data.message);
          }
        })
        .catch(error => {
          this.toastr.error(error);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
  public getSettings(): Promise<any> {
    const apiName = 'api_node_1';
    ;
    const path = `settings/admin`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.get(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          const data = response.data;
          if (data.response_code == 200) {
            resolve(data.data.data);
          }
          else
          {
            return this.toastr.error(data.message);
          }
        })
        .catch(error => {
          this.toastr.error(error);
          reject(error);
        });
    }); // end promoise
    return promise;
  } // end getresult
}
