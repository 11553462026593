import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-submission-telly',
  templateUrl: './submission-telly.component.html',
  styleUrls: ['./submission-telly.component.css']
})
export class SubmissionTellyComponent implements OnInit {

  @Input()
  get quotaData(): any { return this._quotaData; }
  set quotaData(name: any) {
    this._quotaData = (name ) || '<no name set>';
  } 
  constructor() { }

  ngOnInit() {
  }

  private _quotaData = [];
}
