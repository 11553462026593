import { Component, OnInit, ViewChild } from '@angular/core';
import { Page} from '../model/page';
import { CsvModule } from '@ctrl/ngx-csv';import { TransactionService } from '../services/transactionService';
import { TransactionForm } from '../model/TransactionForm';
import { WorkerService } from '../services/workerService';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import {debounceTime, distinctUntilChanged, filter} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import 'rxjs/add/operator/delay';
import { FormControl, Validators } from '@angular/forms';
import { WorkersService } from '../modules/workers/service/workersService';
@Component({
  selector: 'app-transactions',
   templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {
  public selectControl = new FormControl(null, [
    Validators.required
  ]);
  page = new Page();
  selected = [];
  items = [];
  public searchTyped = new BehaviorSubject('');
  message = '';
  amount = 0;
  workerId = null;
  currency= 10;
  range = "0";
  expanded: any = {};
  @ViewChild('myTable') table: any;
  status = null;
  newstatus = 1;
  search='';
  from  =  null;
  to = null;
  rows = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  email = null;
  loading: any = true;
  constructor(private transactionService: TransactionService, private workerService: WorkerService, private toastr: ToastrService, private workersService: WorkersService) {
    this.page.pageNumber = 0;
    this.page.size = 20;
    this.searchTyped.pipe(debounceTime(500),distinctUntilChanged(),filter((str: string) => str.length >= 3))
.subscribe((str) => this.filterWorkers(str));
  }

  ngOnInit() {
       this.setPage({ offset: 0, size:20 });
      //this.filterWorkers(null);
  } // end on init
  filterWorkers(search)
  {
    const p = new Page();
    p.pageNumber= 0;
    p.totalElements=10;
    p.totalPages=1;
    p.size=10;

	if (search) {
		this.workersService.getResult(p, search).then(workers => {
		  this.items = workers.data;
		  this.searchTyped.next('');
		});
	}
  }


  filter() {

    this.transactionService.getResult(this.page, this.email, (this.status == -1? null: this.status),
                                           this.from, this.to).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.loadingIndicator = false;
    });
  }
  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
  getType(status){
    if(status===0) return '<span class="badge badge-info">Submission</span>';
    if(status===2) return '<span class="badge badge-primary">Manual</span>';
    if(status===3) return '<span class="badge badge-danger">Rejection</span>';

    return '<span class="badge badge-info">-</span>';
  }
  setDateRange() {
    let dt = new Date();
    let month = dt.getMonth(), year = dt.getFullYear();
    if(this.range === "0" ) this.from = new Date(year, month, 1);
    if(this.range === "0" ) this.to = new Date(year, month + 1, 0);
    if(this.range === "1" ) this.from = new Date(year, month -1, 1);
    if(this.range === "1" ) this.to = new Date(year, month + 1, 0);
    if(this.range === "2" ) this.from = new Date(year, month -3, 1);
    if(this.range === "2" ) this.to = new Date(year, month + 1, 0);
    if(this.range === "3" ) this.from = new Date(year, 1, 1);
    if(this.range === "3" ) this.to = new Date(year + 1,1, 0);
    this.filter();
  }
  postForm() {
    let ids = [];
    this.selected.map(s => ids.push(s.id));
    if(this.workerId === null) {
      this.toastr.error('Fill all required attributes!');
      return;
    }
    let form = new TransactionForm(null, this.workerId, null, this.amount, this.currency,2,
                                    this.message, 'Inserted Manually', new Date(), new Date(), false);
    this.transactionService.saveTransaction(form);
    this.toastr.success('Transaction Saved Successfully!');
    this.workerId = null;
    this.search=null;
    this.filter();
  }
  onSelect({ selected }) {
   // console.log('Select Event', selected, this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  onActivate(event) {
    //console.log('Activate Event', event);
  }
  setPage(pageInfo) {
    console.log(pageInfo );
    this.page.size = pageInfo.size? pageInfo.size : pageInfo.limit;
    this.page.pageNumber = pageInfo.offset;
    this.transactionService.getResult(this.page, this.email,  (this.status == -1? null: this.status),
            this.from, this.to).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.loadingIndicator = false;
    });
  }
}
