import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { GoogleObj, GoogleService } from "src/app/services/google.services";
import { Sprint, Tag } from "../../model/sprintModel";
import { SprintService } from "../../service/sprintService";

@Component({
	selector: "app-tag",
	templateUrl: "./tag.component.html",
	styleUrls: ["./tag.component.css"],
})
export class TagComponent implements OnInit {
	model: Tag = null;
	_sprint: Sprint = null;
	id: number = 0;

	@Input() sprintId: number;
	@Output("TagSaved") valueChange: EventEmitter<Tag> =
		new EventEmitter<Tag>();

	@Input() rows: any[];

	public googleObj: GoogleObj = new GoogleObj();

	constructor(
		private toastr: ToastrService,
		private _google: GoogleService,
		private sprintService: SprintService
	) {}

	ngOnInit() {
		this.getTag();
	}

	getTag() {
		if (this.id === 0 || this._sprint.rewards == null)
			this.model = {
				id: 0,
				name_ar: "",
				name_en: "",
				project_id: this.sprintId,
			};
		else return this._sprint.rewards.find((r) => r.id === this.id);
	}

	postForm() {
		if (this.model.name_en == "" || this.model.name_ar == "") {
			var dismiss = document.getElementById("submi-tag");
			dismiss.removeAttribute("data-dismiss");
			this.toastr.warning("Both fields are required");
			return false;
		}

		var dismiss = document.getElementById("submi-tag");
		dismiss.setAttribute("data-dismiss", "modal");

		this.sprintService
			.saveTag(this.model)
			.then((data) => {
				this.model.id = data["id"];
				console.log("Saved.." + data);
				this.toastr.success("Saved Successfully!");
				this.valueChange.emit(this.model);
			})
			.catch((err) => {
				this.toastr.error("Error in saving tag");
				console.error(err);
			});
	}

	changeTag(value) {
		this.model = value;
	}

	deleteTag(value) {
		this.model = value;
	}

	translate() {
		this.doTranslate(this.model.name_en).then((d) => {
			this.model.name_ar = d.toString();
		});
	}

	doTranslate(key) {
		return new Promise((resolve, reject) => {
			this._google.translate(this.googleObj, key).subscribe(
				(res: any) => {
					resolve(res.data.translations[0].translatedText);
				},
				(err) => {
					console.log(err);
					reject(err);
				}
			);
		});
	}

	confirmDelete(id)
	{
		this.sprintService
			.deleteTag(id)
			.then((data) => {
				console.log("Delete.." + data);
				this.toastr.success("Delete Successfully!");
				this.valueChange.emit(this.model);
			})
			.catch((err) => {
				this.toastr.error("Error in saving reward");
				console.error(err);
			});
	}
}
