// import { Form, Task, Question, Reward, Targeting } from './../model/sprint';
import { Form, Task, Question, Reward, Targeting } from 'src/app/modules/sprints/model/sprintModel';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
// import { PagedData } from '../model/paged-data';
import { PagedData } from 'src/app/model/paged-data';
// import { ProjectItem } from '../model/projectitem';
// import { ProjectItem } from 'src/app/model/projectitem';
// import { Convert, Sprint } from "../model/sprint";
import { Convert, Sprint } from 'src/app/modules/sprints/model/sprintModel';
// import { Page } from '../model/page';
// import { Page } from 'src/app/submissionFile/model/pageSubmissionModel';
import { Page } from 'src/app/modules/sprints/components/submissionFile/model/pageSubmissionModel';
import API from '@aws-amplify/api';
// import { AuthService } from '../auth/auth.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({providedIn: "root"})
export class RewardService {
  constructor(private auth: AuthService, public loader: LoadingBarService, public toastr: ToastrService) {}
  /**
   * A method that mocks a paged server response
   * @param page The selected page
   * @returns {any} An observable containing the employee data
   */



  public saveReward(model: Reward) {
    const apiName = 'api_node_2';
    const path = `reward`;
    const myInit = {
      body: model,
      headers: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          const data = response;
          console.log("data --->",data);


          if (data.response_code == 200) {
            let value = data.data;
            console.log("value --->",value);

            resolve(value);
          }
          else
          {
            return this.toastr.error(data.message)
          }
        })
        .catch(error => {
          this.toastr.error(error);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
}
