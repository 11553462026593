import { Form, Task, Question, Reward, Targeting, Tag } from "../model/sprintModel";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { PagedData } from "src/app/model/paged-data";
// import { ProjectItem } from "src/app/model/projectitem";
import { ProjectItem } from "src/app/model/ProjectItem";
import { Convert, Sprint } from "../model/sprintModel";
import { Page } from "src/app/modules/sprints/components/submissionFile/model/pageSubmissionModel";
import API from "@aws-amplify/api";
import { AuthService } from "src/app/auth/auth.service";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { e } from "@angular/core/src/render3";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class SprintService {

	parentQuestionTagID:any;

	constructor(
		private auth: AuthService,
		public loader: LoadingBarService,
		public toastr: ToastrService
	) {}
	/**
	 * A method that mocks a paged server response
	 * @param page The selected page
	 * @returns {any} An observable containing the employee data
	 */

	getAllQuestions = [];
	public getResults(
		page: Page,
		search?: any
	): Observable<PagedData<ProjectItem>> {
		return of(this.getPagedData(page, search));
	}

	public getResult(
		page: Page,
		email: string,
		search: string,
		country: number,
		account: number,
		status: number
	): Promise<any> {
		const pagedData = new PagedData<ProjectItem>();
		const apiName = "api_node_1";
		let offset =
			page.pageNumber *
			(page.size === undefined || page.size === NaN ? 30 : page.size);
		const path = `sprints?${status ? "status=" + status : ""}${
			country ? "&country=" + country : ""
		}&limit=${page.size}&offset=${offset}`;
		const myInit = {
			headers: {}, // OPTIONAL
			response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			queryStringParameters: {},
		};
		this.loader.start();
		const promise = new Promise((resolve, reject) => {
			API.get(apiName, path, myInit)
				.then((response) => {
					const data = response.data;
					page.totalElements = data.data.count;
					page.totalPages = page.totalElements / page.size;
					const start = page.pageNumber * page.size;
					const end = Math.min(start + page.size, page.totalElements);
					pagedData.page = page;
					pagedData.data = data;
					this.loader.complete();
					resolve(pagedData);
				})
				.catch((error) => {
					console.log("Error in invoiking API:" + error);
					reject(error);
				});
		}); // end promoise
		return promise;
	} // end getresult

	private getPagedData(page: Page, search): PagedData<ProjectItem> {
		const pagedData = new PagedData<ProjectItem>();
		const apiName = "api_node_1";
		const path = `sprints?email=${search}&limit=${page.size}&offsert=${
			page.pageNumber * page.size
		}`;
		const myInit = {
			headers: {}, // OPTIONAL
			response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			queryStringParameters: {},
		};
		this.loader.start();

		API.get(apiName, path, myInit)
			.then((response) => {
				this.loader.complete();

				const data = response.data;
				page.totalElements = data.count;
				page.totalPages = page.totalElements / page.size;
				const start = page.pageNumber * page.size;
				const end = Math.min(start + page.size, page.totalElements);
				pagedData.data = data.data;
			})
			.catch((error) => {
				console.log("Error in invoiking API:" + error);
			});
		pagedData.page = page;
		return pagedData;
	}

	public getSprint(email: string, id: number): Promise<any> {
		
		let sprint: Sprint = null;
		const apiName = "api_node_1";
		const path = `sprint?email=${email}&id=${id}`;
		const myInit = {
			headers: {}, // OPTIONAL
			response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			queryStringParameters: {},
		};
		this.loader.start();
		const promise = new Promise((resolve, reject) => {
			API.get(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					console.log("response", response.data);
					if (response.data.response_code == 200) {
						let s = response.data.data.data[0];
						// sprint = s;
						sprint = Convert.toSprint(JSON.stringify(s));
						console.log("sprint =>", sprint);

						resolve(sprint);
					} else {
						return this.toastr.error(response.data.message);
					}
				})
				.catch((error) => {
					console.log("Error in invoiking API:" + error);
					reject(error);
				});
		});
		return promise;
	}

	public saveProject(sprint: Sprint): Promise<any> {
		const apiName = "api_node_2";
		const path = `sprint`;
		const myInit = {
			body: sprint,
			headers: {},
		};
		this.loader.start();
		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					const data = response;
					if (data.response_code == 200) {
						let record = data.data;

						resolve(record);
					}
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	public saveTargeting(target: Targeting): Promise<any> {
		const apiName = "api_node_1";
		const path = `sprint/targeting`;
		const myInit = {
			body: target,
			headers: {},
		};
		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					const data = response;
					resolve(data);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	public saveForm(model: Form) {
		const apiName = "api_node_2";
		const path = "form";
		const myInit = {
			body: model,
			headers: {},
		};
		this.loader.start();

		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					const data = response;
					if (data.response_code == 200) {
						resolve(data.data);
					} else {
						return this.toastr.error(data.message);
					}
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	public saveTask(model: Task) {
		const apiName = "api_node_2";
		const path = `task`;
		const myInit = {
			body: model,
			headers: {},
		};
		this.loader.start();
		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					console.log("task save response", response);

					const data = response;
					if (data.response_code == 200) {
						this.loader.complete();

						this.toastr.success(data.message);
						resolve("OK");
					} else {
						return this.toastr.error(data.message);
					}
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	public saveQuestion(model: Question) {
		const apiName = "api_node_2";
		const path = "question";
		const myInit = {
			body: model,
			headers: {}, //
		};
		this.loader.start();

		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					const data = response;
					console.log("data ==>", data);

					if (data.response_code == 200) {
						resolve(data.data);
					} else {
						return this.toastr.error(data.message);
					}
				})
				.catch((error) => {
					this.toastr.error(error);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	public saveTag(model: Tag) {
		const apiName = "api_node_2";
		const path = "sprints/tags/save";
		const myInit = {
			body: model,
			headers: {}, //
		};
		this.loader.start();

		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					const data = response;
					console.log("data ==>", data);

					if (data.response_code == 200) {
						resolve(data.data);
					} else {
						return this.toastr.error(data.message);
					}
				})
				.catch((error) => {
					this.toastr.error(error);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	public deleteTag(id: number): Promise<any> {
		const apiName = "api_node_2";
		const path = `sprints/tags?id=${id}`;
		const myInit = {
			headers: {}, // OPTIONAL
			response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			queryStringParameters: {},
		};
		this.loader.start();
		const promise = new Promise((resolve, reject) => {
			API.del(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					console.log("response", response.data);
					if (response.data.response_code == 200) {
						let s = response.data.data;

						resolve(s);
					} else {
						return this.toastr.error(response.data.message);
					}
				})
				.catch((error) => {
					console.log("Error in invoiking API:" + error);
					reject(error);
				});
		});
		return promise;
	}

	public getSprintTags(id: number): Promise<any> {
		const apiName = "api_node_2";
		const path = `sprints/tags?project_id=${id}`;
		const myInit = {
			headers: {}, // OPTIONAL
			response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			queryStringParameters: {},
		};
		this.loader.start();
		const promise = new Promise((resolve, reject) => {
			API.get(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					console.log("response", response.data);
					if (response.data.response_code == 200) {
						const tagLists = response.data.data;
						console.log("tag list ==> ", tagLists);
						resolve(tagLists);
					} else {
						return this.toastr.error(response.data.message);
					}
				})
				.catch((error) => {
					console.log("Error in invoiking API:" + error);
					reject(error);
				});
		});
		return promise;
	}

	public ParentTagLists(id: number): Promise<any> {
		const apiName = "api_node_2";
		const path = `questions/tags?id=${id}`;
		const myInit = {
			headers: {}, // OPTIONAL
			response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			queryStringParameters: {},
		};
		this.loader.start();
		const promise = new Promise((resolve, reject) => {
			API.get(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					console.log("response", response.data);
					if (response.data.response_code == 200) {
						const parentTagList = response.data.data;
						console.log("tag list ==> ", parentTagList);
						resolve(parentTagList);
					} else {
						return this.toastr.error(response.data.message);
					}
				})
				.catch((error) => {
					console.log("Error in invoiking API:" + error);
					reject(error);
				});
		});
		return promise;
	}

	public sprintLists(): Promise<any> {
		const apiName = "api_node_2";
		const path = `sprint/list`;
		const myInit = {
			headers: {}, // OPTIONAL
			response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
			queryStringParameters: {},
		};
		this.loader.start();
		const promise = new Promise((resolve, reject) => {
			API.get(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					console.log("response", response.data);
					if (response.data.response_code == 200) {
						const sprintLists = response.data.data;
						resolve(sprintLists);
					} else {
						return this.toastr.error(response.data.message);
					}
				})
				.catch((error) => {
					console.log("Error in invoiking API:" + error);
					reject(error);
				});
		});
		return promise;
	}

	public dublicateTaskFormData(data) {
		const apiName = "api_node_2";
		const path = "sprint/data-duplication";
		const myInit = {
			body: data,
			headers: {},
		};
		this.loader.start();

		const promise = new Promise((resolve, reject) => {
			API.post(apiName, path, myInit)
				.then((response) => {
					this.loader.complete();

					const data = response;
					if (data.response_code == 200) {
						this.toastr.success(data.message)
						resolve(data.data);
					} else {
						return this.toastr.error(data.message);
					}
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		}); // end promoise
		return promise;
	}

	utcToLocal(date)
	{
		if(date) {
			const dateTime = new Date(date+"Z");
			const local = dateTime.getTime();
			const offset = dateTime.getTimezoneOffset() * (60 * 1000);
			const utc = new Date(local + offset);
			const riyadh = new Date(utc.getTime() + (3 * 60 * 60 * 1000));
			
			return riyadh;
		}
		return date;
		
	}
}
