import { Component, OnInit, OnDestroy, AfterViewInit  } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from './auth/auth.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  public loggedIn: boolean;
  cssUrl = '../src/assets/css/sitear.css';
  title = 'Muhimma Portal';
  signedIn: boolean;
  user: any;
  role = 'admin';
  greeting: string;
  isCollapsed:boolean;
  isSale = false;
loading;
  constructor(private amplifyService: AmplifyService, private translate: TranslateService,
              public sanitizer: DomSanitizer, public auth: AuthService,private router: Router,config: NgbTooltipConfig) {
    translate.setDefaultLang('en');
    this.loading = true;
    config.placement = 'right-top';
    config.container = 'body';
    config.triggers = 'hover';
    this.isCollapsed = true;
    this.amplifyService = amplifyService;
    this.amplifyService.authStateChange$
      .subscribe(authState => {
        this.signedIn = authState.state === 'signedIn';
        if (!authState.user) {
          router.navigate['/login'];
          this.user = null;
        } else {
          this.user = authState.user;
          
          this.greeting = 'Hellooooooo ' + this.user.username;
          //TODO: make a real role auth thing
          this.role = this.user.attributes['email'].indexOf('sales') > -1 ? 'sales':'admin';
          
          //sales@muhimmaapp.com, sales151L3s
          

        }
      });
    // this.switchLanguage('ar');
  } // end constructor
  ngAfterViewInit() {
    this.router.events
        .subscribe((event) => {
            if(event instanceof NavigationStart) {
                this.loading = true;
            }
            else if (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel
                ) {
                this.loading = false;
            }
        });
}
  ngOnInit() {
    var toggle = true;

    this.subscription = this.auth.isAuthenticated()
      .subscribe(result => {
        this.loggedIn = result;
      });
    $(document).ready(function () {
      $(".sidebar-icon").click(function () {
        if (toggle) {
          $(".page-container").addClass("sidebar-collapsed").removeClass("sidebar-collapsed-back");
          $("#menu span").css({ "position": "absolute" });
        }
        else {
          $(".page-container").removeClass("sidebar-collapsed").addClass("sidebar-collapsed-back");
          setTimeout(function () {
            $("#menu span").css({ "position": "relative" });
          }, 300);
        }
        toggle = !toggle;
      });

    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickLogout() {
    this.auth.signOut();
  }
  onClickLogin() {
    // tslint:disable-next-line:max-line-length
    const url = 'https://frontendportal.auth.us-east-2.amazoncognito.com/login?response_type=code&client_id= 6rkscc08nrb2puv0690hgjhcnm&redirect_uri=https://localhost:4200';
    window.location.assign(url);
  }
  switchLanguage(language: string) {
    this.translate.use(language);
    // this.cssUrl = language == 'en' ? '/src/assets/css/site.css' : '/src/assets/css/sitear.css';
    window['switchStyle'](language);

  }// end switchanguage
} // end class
