import { AreaService } from "src/app/services/area.service";
import {
	Component,
	OnInit,
	ViewChild,
	EventEmitter,
	Inject,
} from "@angular/core";
import { FormControl } from "@angular/forms";
// import { Task, Sprint } from 'src/app/model/sprint';
import { Task, Sprint } from "../../../model/sprintModel";
import { Page } from "src/app/model/page";
import { ProjectService } from "src/app/services/projectService";
import { ActivatedRoute, Router } from "@angular/router";
import { BreadCrumb } from "src/app/model/BreadCrumb";
import { ToastrService } from "ngx-toastr";
import { GoogleService, GoogleObj } from "src/app/services/google.services";
import { User } from "src/app/model/user";
import { SprintService } from "src/app/modules/sprints/service/sprintService";
import { Options } from "ng5-slider";

@Component({
	selector: "app-task-edit",
	templateUrl: "./task-edit.component.html",
	styleUrls: ["./task-edit.component.css"],
})
export class TaskEditComponent implements OnInit {
	crumbs: BreadCrumb[];
	isShown: boolean = false;
	minValue: number = 1;
	maxValue: number = 50;
	formData: FormData;
	humanizeBytes: Function;
	dragOver: boolean;
	submitted = false;
	Sprint: Sprint = null;
	loading = true;
	email = null;
	id: number = null;
	projectId: number = null;
	model: Task = null;
	areas: any[] = null;
	user: User = null;
	genders: any[] = [
		{ id: 2, name: "All" },
		{ id: 0, name: "Male" },
		{ id: 1, name: "Female" },
	];
	oses: any[] = [
		{ id: "all", name: "All" },
		{ id: "Android", name: "Android" },
		{ id: "iOS", name: "IOS" },
		{ id: "web", name: "Web" },
	];
	countries: any[] = [
		{ id: 0, name: "Global" },
		{ id: 1, name: "Afghanistan" },
		{ id: 2, name: "Albania" },
		{ id: 3, name: "Algeria" },
		{ id: 4, name: "Andorra" },
		{ id: 5, name: "Angola" },
		{ id: 6, name: "Anguilla" },
		{ id: 7, name: "Antigua and Barbuda" },
		{ id: 8, name: "Argentina" },
		{ id: 9, name: "Armenia" },
		{ id: 10, name: "Australia" },
		{ id: 11, name: "Austria" },
		{ id: 12, name: "Azerbaijan" },
		{ id: 13, name: "Bahrain" },
		{ id: 14, name: "Bangladesh" },
		{ id: 15, name: "Barbados" },
		{ id: 16, name: "Belarus" },
		{ id: 17, name: "Belgium" },
		{ id: 18, name: "Belize" },
		{ id: 19, name: "Benin" },
		{ id: 20, name: "Bermuda" },
		{ id: 21, name: "Bhutan" },
		{ id: 22, name: "Bolivia" },
		{ id: 23, name: "Bosnia and Herzegovina" },
		{ id: 24, name: "Botswana" },
		{ id: 25, name: "Brazil" },
		{ id: 26, name: "British Indian Ocean Territory" },
		{ id: 27, name: "Virgin Islands (British)" },
		{ id: 28, name: "Brunei" },
		{ id: 29, name: "Bulgaria" },
		{ id: 30, name: "Burkina Faso" },
		{ id: 31, name: "Burundi" },
		{ id: 32, name: "Cambodia" },
		{ id: 33, name: "Cameroon" },
		{ id: 34, name: "Canada" },
		{ id: 35, name: "Cape Verde" },
		{ id: 36, name: "Cayman Islands" },
		{ id: 37, name: "Central African Republic" },
		{ id: 38, name: "Chad" },
		{ id: 39, name: "Chile" },
		{ id: 40, name: "China" },
		{ id: 41, name: "Colombia" },
		{ id: 42, name: "Comoros" },
		{ id: 43, name: "Republic of the Congo" },
		{ id: 44, name: "Democratic Republic of the Congo" },
		{ id: 45, name: "Cook Islands" },
		{ id: 46, name: "Costa Rica" },
		{ id: 47, name: "Croatia" },
		{ id: 48, name: "Cuba" },
		{ id: 49, name: "Cyprus" },
		{ id: 50, name: "Czech Republic" },
		{ id: 51, name: "Ivory Coast" },
		{ id: 52, name: "Denmark" },
		{ id: 53, name: "Djibouti" },
		{ id: 54, name: "Dominica" },
		{ id: 55, name: "Dominican Republic" },
		{ id: 56, name: "East Timor" },
		{ id: 57, name: "Ecuador" },
		{ id: 58, name: "Egypt" },
		{ id: 59, name: "El Salvador" },
		{ id: 60, name: "Equatorial Guinea" },
		{ id: 61, name: "Eritrea" },
		{ id: 62, name: "Estonia" },
		{ id: 63, name: "Ethiopia" },
		{ id: 64, name: "Falkland Islands" },
		{ id: 65, name: "Faroe Islands" },
		{ id: 66, name: "Federated States of Micronesia" },
		{ id: 67, name: "Fiji" },
		{ id: 68, name: "Finland" },
		{ id: 69, name: "France" },
		{ id: 70, name: "Gabon" },
		{ id: 71, name: "The Gambia" },
		{ id: 72, name: "Georgia" },
		{ id: 73, name: "Germany" },
		{ id: 74, name: "Ghana" },
		{ id: 75, name: "Gibraltar" },
		{ id: 76, name: "Greece" },
		{ id: 77, name: "Greenland" },
		{ id: 78, name: "Grenada" },
		{ id: 79, name: "Guatemala" },
		{ id: 80, name: "Guernsey" },
		{ id: 81, name: "Guinea" },
		{ id: 82, name: "Guinea-Bissau" },
		{ id: 83, name: "Guyana" },
		{ id: 84, name: "Haiti" },
		{ id: 85, name: "Honduras" },
		{ id: 86, name: "Hungary" },
		{ id: 87, name: "Iceland" },
		{ id: 88, name: "India" },
		{ id: 89, name: "Indonesia" },
		{ id: 90, name: "Iran" },
		{ id: 91, name: "Iraq" },
		{ id: 92, name: "Republic of Ireland" },
		{ id: 93, name: "Isle of Man" },
		{ id: 94, name: "Israel" },
		{ id: 95, name: "Italy" },
		{ id: 96, name: "Jamaica" },
		{ id: 97, name: "Japan" },
		{ id: 98, name: "Jersey" },
		{ id: 99, name: "Jordan" },
		{ id: 100, name: "Kazakhstan" },
		{ id: 101, name: "Kenya" },
		{ id: 102, name: "Kiribati" },
		{ id: 103, name: "Republic of Kosovo" },
		{ id: 104, name: "Kuwait" },
		{ id: 105, name: "Kyrgyzstan" },
		{ id: 106, name: "Laos" },
		{ id: 107, name: "Latvia" },
		{ id: 108, name: "Lebanon" },
		{ id: 109, name: "Lesotho" },
		{ id: 110, name: "Liberia" },
		{ id: 111, name: "Libya" },
		{ id: 112, name: "Liechtenstein" },
		{ id: 113, name: "Lithuania" },
		{ id: 114, name: "Luxembourg" },
		{ id: 115, name: "Republic of Macedonia" },
		{ id: 116, name: "Madagascar" },
		{ id: 117, name: "Malawi" },
		{ id: 118, name: "Malaysia" },
		{ id: 119, name: "Maldives" },
		{ id: 120, name: "Mali" },
		{ id: 121, name: "Malta" },
		{ id: 122, name: "Marshall Islands" },
		{ id: 123, name: "Mauritania" },
		{ id: 124, name: "Mauritius" },
		{ id: 125, name: "Mexico" },
		{ id: 126, name: "Moldova" },
		{ id: 127, name: "Monaco" },
		{ id: 128, name: "Mongolia" },
		{ id: 129, name: "Montenegro" },
		{ id: 130, name: "Montserrat" },
		{ id: 131, name: "Morocco" },
		{ id: 132, name: "Mozambique" },
		{ id: 133, name: "Myanmar" },
		{ id: 134, name: "Namibia" },
		{ id: 135, name: "Nauru" },
		{ id: 136, name: "Nepal" },
		{ id: 137, name: "New Zealand" },
		{ id: 138, name: "Nicaragua" },
		{ id: 139, name: "Niger" },
		{ id: 140, name: "Nigeria" },
		{ id: 141, name: "Niue" },
		{ id: 142, name: "North Korea" },
		{ id: 143, name: "Norway" },
		{ id: 144, name: "Oman" },
		{ id: 145, name: "Pakistan" },
		{ id: 146, name: "Palau" },
		{ id: 147, name: "Palestine" },
		{ id: 148, name: "Panama" },
		{ id: 149, name: "Papua New Guinea" },
		{ id: 150, name: "Paraguay" },
		{ id: 151, name: "Peru" },
		{ id: 152, name: "Philippines" },
		{ id: 153, name: "Pitcairn Islands" },
		{ id: 154, name: "Poland" },
		{ id: 155, name: "Portugal" },
		{ id: 156, name: "Qatar" },
		{ id: 157, name: "Romania" },
		{ id: 158, name: "Russia" },
		{ id: 159, name: "Rwanda" },
		{ id: 160, name: "Saint Helena" },
		{ id: 161, name: "Saint Kitts and Nevis" },
		{ id: 162, name: "Saint Lucia" },
		{ id: 163, name: "Saint Vincent and the Grenadines" },
		{ id: 164, name: "Samoa" },
		{ id: 165, name: "San Marino" },
		{ id: 166, name: "Saudi Arabia" },
		{ id: 167, name: "Senegal" },
		{ id: 168, name: "Serbia" },
		{ id: 169, name: "Seychelles" },
		{ id: 170, name: "Sierra Leone" },
		{ id: 171, name: "Singapore" },
		{ id: 172, name: "Slovakia" },
		{ id: 173, name: "Slovenia" },
		{ id: 174, name: "Solomon Islands" },
		{ id: 175, name: "Somalia" },
		{ id: 176, name: "South Africa" },
		{ id: 177, name: "South Georgia" },
		{ id: 178, name: "South Korea" },
		{ id: 179, name: "South Sudan" },
		{ id: 180, name: "Spain" },
		{ id: 181, name: "Sri Lanka" },
		{ id: 182, name: "Sudan" },
		{ id: 183, name: "Suriname" },
		{ id: 184, name: "Swaziland" },
		{ id: 185, name: "Sweden" },
		{ id: 186, name: "Switzerland" },
		{ id: 187, name: "Syria" },
		{ id: 188, name: "São Tomé and Príncipe" },
		{ id: 189, name: "Taiwan" },
		{ id: 190, name: "Tajikistan" },
		{ id: 191, name: "Tanzania" },
		{ id: 192, name: "Thailand" },
		{ id: 193, name: "The Bahamas" },
		{ id: 194, name: "Netherlands" },
		{ id: 195, name: "Togo" },
		{ id: 196, name: "Tokelau" },
		{ id: 197, name: "Tonga" },
		{ id: 198, name: "Trinidad and Tobago" },
		{ id: 199, name: "Tunisia" },
		{ id: 200, name: "Turkey" },
		{ id: 201, name: "Turkmenistan" },
		{ id: 202, name: "Turks and Caicos Islands" },
		{ id: 203, name: "Tuvalu" },
		{ id: 204, name: "Uganda" },
		{ id: 205, name: "Ukraine" },
		{ id: 206, name: "United Arab Emirates" },
		{ id: 207, name: "United Kingdom" },
		{ id: 208, name: "United States" },
		{ id: 209, name: "Uruguay" },
		{ id: 210, name: "Uzbekistan" },
		{ id: 211, name: "Vanuatu" },
		{ id: 212, name: "Holy See" },
		{ id: 213, name: "Venezuela" },
		{ id: 214, name: "Vietnam" },
		{ id: 215, name: "Yemen" },
		{ id: 216, name: "Zambia" },
		{ id: 217, name: "Zimbabwe" },
	];

	options: Options = {
		floor: 0,
		ceil: 99,
	};

	saveQuota = 0;

	public googleObj: GoogleObj = new GoogleObj();

	constructor(
		private projectService: SprintService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private toastr: ToastrService,
		private _google: GoogleService,
		private areaService: AreaService
	) {}

	ngOnInit() {
		
		this.user = JSON.parse(localStorage.getItem("user")) as User;

		this.activatedRoute.queryParams.subscribe((params) => {
			this.projectId = +this.activatedRoute.snapshot.paramMap.get("id");

			this.id = +this.activatedRoute.snapshot.paramMap.get("task");
			this.hideDelbutton(this.id);
			this.projectService
				.getSprint(this.email, this.projectId)
				.then((sprint) => {
					this.Sprint = sprint;
					console.log(this.Sprint);
					if (this.id > 0) {
						this.model = this.Sprint.tasks.find(
							(t) => t.id === this.id
						);
					} else {
						this.model = {
							id: null,
							title: "",
							titlear: "",
							task_form_id: null,
							status: 0,
							max_submission_count: 100,
							max_submission_count_per_worker: 1,
							area_id: null,
							address: null,
							amount: 0,
							related_task_ids: null,
							hard_prerequisite_ids: null,
							reward_id: null,
							created_at: new Date(),
							updated_at: new Date(),
							location: null,
							min_age: 1,
							max_age: 99,
							os: 0,
							gender: 2,
							description: "",
							descriptionar: "",
							short_description: "",
							short_description_ar: "",
							is_current_location: true,
							age_constraints: [{ value: [0, 99], quota: 100 }],
							gender_constraints: [{ value: [2], quota: 100 }],
							os_constraints: [{ value: ["all"], quota: 100 }],
							nationality_constraints: [{ value: [0], quota: 100 }],
							area_constraints: [{ value: [0], quota: 100 }],
						};
					}

					if (this.model.age_constraints == null) {
						this.model.age_constraints = [
							{
								value: [0, 99],
								quota: this.model.max_submission_count,
							},
						];
					}
					if (this.model.gender_constraints == null) {
						this.model.gender_constraints = [
							{
								value: [2],
								quota: this.model.max_submission_count,
							},
						];
					}
					if (this.model.os_constraints == null) {
						this.model.os_constraints = [
							{
								value: ["all"],
								quota: this.model.max_submission_count,
							},
						];
					}
					if (this.model.nationality_constraints == null) {
						this.model.nationality_constraints = [
							{
								value: [0],
								quota: this.model.max_submission_count,
							},
						];
					}
					if (this.model.area_constraints == null) {
						this.model.area_constraints = [
							{
								value: [0],
								quota: this.model.max_submission_count,
							},
						];
					}

					this.saveQuota = this.model.max_submission_count;
					console.log(this.model);
					this.buildBreadCrumb();
					this.loading = false;
					this.areaService.getAreas().then((a) => {
						let globalArray = [{id: 0 , name: "Global"}];

						this.areas = [...globalArray, ...a];

						if (this.user.role === "a") {
							this.areas = a.filter(
								(c) => c.parent_id === this.user.country_id
							);
						}
					});
				});
		});
	} //eznd on init
	translate() {
		this.doTranslate(this.model.title).then((d) => {
			this.model.titlear = d.toString();
		});
		this.doTranslate(this.model.description).then((d) => {
			this.model.descriptionar = d.toString();
		});
		this.doTranslate(this.model.short_description).then((d) => {
			this.model.short_description_ar = d.toString();
		});
	}
	hideDelbutton(id) {
		if (id != 0) {
			this.isShown = !this.isShown;
		} else {
			this.isShown == this.isShown;
		}
	}
	doTranslate(key) {
		return new Promise((resolve, reject) => {
			this._google.translate(this.googleObj, key).subscribe(
				(res: any) => {
					resolve(res.data.translations[0].translatedText);
				},
				(err) => {
					console.log(err);
					reject(err);
				}
			);
		});
	}
	onSubmit() {
		this.submitted = true;
	}
	buildBreadCrumb() {
		//Sprints? Sprint()>Task Forms ()
		this.crumbs = [];
		this.crumbs.push(new BreadCrumb("Home", "/"));

		this.crumbs.push(new BreadCrumb("Sprints", "/sprints"));
		this.crumbs.push(
			new BreadCrumb(this.Sprint.name, "/sprints/" + this.Sprint.id)
		);
		this.crumbs.push(
			new BreadCrumb(this.model.title ? this.model.title : "Task", "")
		);
	}
	delete() {
		this.model.is_deleted = true;
		this.projectService
			.saveTask(this.model)
			.then((data) => {
				this.toastr.success("Deleted Successfully!");
				this.back();
			})
			.catch((err) => {
				this.toastr.error("Error in");
				// console.error(err);
			});
		//this.back();
	} //end post form
	postForm() {
		
		if (this.model.description === null) this.model.description = "";
		if (this.model.descriptionar === null) this.model.descriptionar = "";
		if (this.model.short_description === null)
			this.model.short_description = "";
		if (this.model.short_description_ar === null)
			this.model.short_description_ar = "";

		if (this.model.titlear == "") {
			this.toastr.warning("Please insert in the Arabic Title");
			return false;
		}
		if (this.model.reward_id == null) {
			this.toastr.warning("Reward must be selected");
			return false;
		}

		if (this.model.task_form_id == null) {
			this.toastr.warning("Taskform must be selected");
			return false;
		}

		if (
			this.model.max_submission_count == 0 ||
			this.model.max_submission_count == null ||
			!this.model.max_submission_count
		) {
			this.toastr.warning("Quota must be greater than 0");
			return false;
		}

		if (
			this.model.max_submission_count_per_worker == 0 ||
			this.model.max_submission_count_per_worker == null ||
			!this.model.max_submission_count_per_worker
		) {
			this.toastr.warning("Quota Per Worker must be greater than 0");
			return false;
		}
		if (this.model.submission_radius == null) {
			this.toastr.warning("Please Enter the Submission Radius");
			return false;
		}
		if (this.model.claim_radius == null) {
			this.toastr.warning("Please Enter the Claim Radius");
			return false;
		}

		let bolCheck = false;
		let globalCheck = false;
		let bolCheckValue = false;
		let message;
		let globalMessage;
		let valueName;
		this.model.age_constraints.forEach((element) => {
			if (element.quota == 0) {
				bolCheck = true;
				message = "Age";
			}
		});
		this.model.gender_constraints.forEach((element) => {
			if (element.quota == 0) {
				bolCheck = true;
				message = "Gender";
			}
			if (this.model.gender_constraints.length > 1) {
				const value = element.value.filter(x => x == 2);
				if (value.length > 0) {
					globalCheck = true;
					globalMessage = "Can not add an additional criteria with All option";
				}
			} else {
				const value = element.value.filter(x => x == 2);
				if (value.length > 0 && element.value.length > 1) {
					globalCheck = true;
					globalMessage = "Can not add an additional criteria with All option";
				}
			}
		});
		this.model.nationality_constraints.forEach((element) => {
			if (element.quota == 0) {
				bolCheck = true;
				message = "Nationality";
			}
			if (this.model.nationality_constraints.length > 1) {
				const value = element.value.filter(x => x == 0);
				if (value.length > 0) {
					globalCheck = true;
					globalMessage = "Can not add an additional criteria with Global option";
				}
			} else {
				const value = element.value.filter(x => x == 0);
				if (value.length > 0 && element.value.length > 1) {
					globalCheck = true;
					globalMessage = "Can not add an additional criteria with Global option";
				}
			}
		});
		this.model.area_constraints.forEach((element) => {
			if (element.quota == 0) {
				bolCheck = true;
				message = "Area";
			}
			if (this.model.area_constraints.length > 1) {
				const value = element.value.filter(x => x == 0);
				if (value.length > 0) {
					globalCheck = true;
					globalMessage = "Can not add an additional criteria with Global option";
				}
			} else {
				const value = element.value.filter(x => x == 0);
				if (value.length > 0 && element.value.length > 1) {
					globalCheck = true;
					globalMessage = "Can not add an additional criteria with Global option";
				}
			}
		});
		this.model.os_constraints.forEach((element) => {
			if (element.quota == 0) {
				bolCheck = true;
				message = "OS";
			}
			if (this.model.os_constraints.length > 1) {
				const value = element.value.filter(x => x == "all");
				if (value.length > 0) {
					globalCheck = true;
					globalMessage = "Can not add an additional criteria with All option";
				}
			} else {
				const value = element.value.filter(x => x == "all");
				if (value.length > 0 && element.value.length > 1) {
					globalCheck = true;
					globalMessage = "Can not add an additional criteria with All option";
				}
			}
		});

		if (bolCheck) {
			this.toastr.warning(
				"Quota of " + message + " must be greater than 0"
			);
			return false;
		}
		if (bolCheckValue) {
			this.toastr.warning(valueName + " must be selected");
			return false;
		}

		if (globalCheck) {
			this.toastr.warning(globalMessage);
			return false;
		}
		this.projectService
			.saveTask(this.model)
			.then((data) => {
				this.toastr.success("Saved Successfully!");
				this.back();
			})
			.catch((err) => {
				this.toastr.error("Error in");
				// console.error(err);
			});
		//this.back();
	} //end post form

	back() {
		this.router.navigate(["sprints", this.Sprint.id]);
	}

	addAgeConstrait() {
		let quotaCount = 0;
		this.model.age_constraints.forEach((elementAge) => {
			quotaCount = quotaCount + Number(elementAge.quota);
		});
		if (quotaCount < this.model.max_submission_count) {
			let remainingQuota = this.model.max_submission_count - quotaCount;
			this.model.age_constraints.push({
				value: [0, 99],
				quota: remainingQuota,
			});
		} else {
			this.toastr.warning("Age Quoata is full");
		}
	}

	addGenderConstrait() {
		let quotaCount = 0;
		let checkAll: boolean = false;
		this.model.gender_constraints.forEach((elementG) => {
			quotaCount = quotaCount + Number(elementG.quota);
			elementG.value.forEach(value => {
				if (value == 2) {
					checkAll = true;
				}
			});

		});
		if (quotaCount < this.model.max_submission_count) {
			let remainingQuota = this.model.max_submission_count - quotaCount;
			if (!checkAll) {
				if (this.model.gender_constraints.length < 3) {
					this.model.gender_constraints.push({
						value: [0],
						quota: remainingQuota,
					});
				} else {
					this.toastr.warning("No add more gender fields");
				}
			} else {
				return this.toastr.warning("Can not add an additional criteria with All option");
			}
		} else {
			this.toastr.warning("Gender Quoata is full");
		}
	}
	addOSConstrait() {
		let quotaCount = 0;
		let checkAll: boolean = false;
		this.model.os_constraints.forEach((elementOS) => {
			quotaCount = quotaCount + Number(elementOS.quota);
			elementOS.value.forEach(value => {
				if (value == 'all') {
					checkAll = true;
				}
			});
		});
		if (quotaCount < this.model.max_submission_count) {
			let remainingQuota = this.model.max_submission_count - quotaCount;
			if (!checkAll) {
				if (this.model.os_constraints.length < 4) {
					this.model.os_constraints.push({
						value: ["all"],
						quota: remainingQuota,
					});
				} else {
					this.toastr.warning("No add more OS fields");
				}
			} else {
				return this.toastr.warning("Can not add an additional criteria with All option");
			}
		} else {
			this.toastr.warning("OS Quoata is full");
		}
	}
	addNationalityConstrait() {
		let quotaCount = 0;
		let checkAll: boolean = false;
		this.model.nationality_constraints.forEach((elementN) => {
			quotaCount = quotaCount + Number(elementN.quota);
			elementN.value.forEach(value => {
				if (value == 0) {
					checkAll = true;
				}
			});
		});
		if (quotaCount < this.model.max_submission_count) {
			let remainingQuota = this.model.max_submission_count - quotaCount;
			if (!checkAll) {
				this.model.nationality_constraints.push({
					value: [0],
					quota: remainingQuota,
				});
			} else {
				return this.toastr.warning("Can not add an additional criteria with Global option");
			}
		} else {
			this.toastr.warning("Nationality Quoata is full");
		}
	}
	addAreaConstrait() {
		let quotaCount = 0;
		let checkAll: boolean = false;
		this.model.area_constraints.forEach((elementA) => {
			quotaCount = quotaCount + Number(elementA.quota);
			elementA.value.forEach(value => {
				if (value == 0) {
					checkAll = true;
				}
			});
		});
		console.log(quotaCount);
		if (quotaCount < this.model.max_submission_count) {
			let remainingQuota = this.model.max_submission_count - quotaCount;
			if (!checkAll) {
				this.model.area_constraints.push({
					value: [0],
					quota: remainingQuota,
				});
			} else {
				return this.toastr.warning("Can not add an additional criteria with Global option");
			}
		} else {
			this.toastr.warning("Area Quoata is full");
		}
	}

	valAreaQuaota(arrayVal, event, index) {
		console.log(arrayVal);
		console.log(event.target.value);
		let saveVal = 0;
		let remianingVal = 0;
		let updateVal = 0;
		arrayVal.forEach((elementArr, ind) => {
			saveVal = saveVal + Number(elementArr.quota);

			if (ind == index) {
				updateVal = Number(elementArr.quota);
			}
		});
		console.log(saveVal);
		if (saveVal > this.model.max_submission_count) {
			// remianingVal = this.model.max_submission_count - saveVal;
			remianingVal =
				this.model.max_submission_count - (saveVal - updateVal);
			arrayVal[index].quota = remianingVal;
			return this.toastr.warning(
				"You cannot enter the value above the quota"
			);
		}
	}

	changeQuota() {
		console.log(this.saveQuota);
		this.model.age_constraints.forEach((element) => {
			element.quota = 0;
		});
		this.model.gender_constraints.forEach((element) => {
			element.quota = 0;
		});
		this.model.nationality_constraints.forEach((element) => {
			element.quota = 0;
		});
		this.model.area_constraints.forEach((element) => {
			element.quota = 0;
		});
		this.model.os_constraints.forEach((element) => {
			element.quota = 0;
		});
		// this.model.age_constraints = [{value: [0,99], quota: this.model.max_submission_count}];
		// this.model.gender_constraints = [{value: 0, quota: this.model.max_submission_count}];
		// this.model.nationality_constraints = [{value: null, quota: this.model.max_submission_count}];
		// this.model.area_constraints = [{value: null, quota: this.model.max_submission_count}];
		// this.model.os_constraints = [{value: 0, quota: this.model.max_submission_count}];
	}
} //end xomponent
