// import {File, Targeting} from './../model/sprint';
import { File, Targeting} from 'src/app/model/sprint';
// import { S3Response } from './../model/S3Response';
import { S3Response } from 'src/app/model/S3Response';
// import { AccountService } from './../services/accountService';
import { AccountService } from 'src/app/services/accountService';
import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
// import { Sprint, File as Upload } from '../model/sprint';
import { File as Upload } from 'src/app/model/sprint';
// import { Sprint, File as Upload } from 'src/app/model/sprint';
import { Convert, Sprint } from '../../model/sprintModel';
// import { Page } from '../model/page';
// import { Page } from 'src/app/model/page';
import { Page } from 'src/app/modules/sprints/components/submissionFile/model/pageSubmissionModel';
import { ProjectService } from 'src/app/services/projectService';
import { SprintService } from '../../service/sprintService';
// import { ProjectService } from '../services/projectService';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { Options } from 'ng5-slider';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { base64Response } from '../model/Base64Reponse';
import { base64Response } from 'src/app/model/Base64Reponse';
// import { BreadCrumb } from '../model/BreadCrumb';
import { BreadCrumb } from 'src/app/model/BreadCrumb';
import { ToastrService } from 'ngx-toastr';
// import { GoogleService, GoogleObj } from '../services/google.services';
import { GoogleService, GoogleObj } from 'src/app/services/google.services';
// import { User } from '../model/user';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-sprint-edit',
  templateUrl: './sprint-edit.component.html',
  styleUrls: ['./sprint-edit.component.css']
})
export class SprintEditComponent implements OnInit {

  uploadUrl = 'https://bu0vboz957.execute-api.us-east-2.amazonaws.com/development/file?name=';
  crumbs: BreadCrumb[];
  public googleObj: GoogleObj = new GoogleObj();
  public startDate: any = new Date();
  public endDate: any;

  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  backroundFile: UploadFile;
  iconFile: UploadFile;
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  submitted = false;
  schedule = false;
  availability = false;
  minValue = 1;
  maxValue = 99;
  allowed = null;
  disallowed = null;
  os = 0;
  gender = 0;
  imageUrl = '';
  from = new Date();
  to = new Date(2020, 1, 1);
  sliderOptions: Options = {
    floor: 0,
    ceil: 100,
    step: 5
  };
  accounts = [];
  countries: any[] = [{ 'id': 206, 'name': '  United Arab Emirates', 'image': '/assets/icons/uae-round.png' },
  { 'id': 166, 'name': '  Saudi Arabia', 'image': '/assets/icons/ksa-round.png' },
  { 'id': 58, 'name': '  Egypt', 'image': '/assets/icons/egypt-round.png' },
  { 'id': 999, 'name': '     Global', 'image': '/assets/icons/earth1.png' }];
  quill: any = null;
  loading = true;
  email = null;
  id = null;
  user: User = null;
  model: Sprint = {
    id: null, name: '', namear: '', account_id: null, customer_name: 'Muhimma', status: 0, country_id: 999,
    is_auto_acceptable: true, is_community: false, is_pilot: false, tag: 'Muhimma', forms: [], tasks: [], files: [],
    rewards: [], priority: 5, created_at: new Date(), updated_at: new Date(), schedulings: [], system_name: '',
    max_submission_count:100, max_submission_count_per_worker:1,
    // tslint:disable-next-line:max-line-length
    description: '', descriptionar: '', short_description: '',short_description_ar: '',  extra_text: '', is_deleted: false, started_at: new Date(), finished_at: null, area_id: null, iconurl: null, backgroundurl: ''
  };


  constructor(private accountService: AccountService, private sprintService: SprintService, private _google: GoogleService,
    private activatedRoute: ActivatedRoute, private http: HttpClient, private router: Router, private toastr: ToastrService) {
    this.filterAccounts(null);
    this.options = { concurrency: 1, maxUploads: 3 };
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }
  onEditorCreated(quill) {
    const toolbar = quill.getModule('toolbar');
    toolbar.addHandler('image', this.imageHandler());
    this.quill = quill;
    console.log('Editor Created was invoked' + quill);
  }

  imageHandler() {
    const Imageinput = document.createElement('input');
    Imageinput.setAttribute('type', 'file');
    Imageinput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
    Imageinput.classList.add('ql-image');

    Imageinput.addEventListener('change', () => {
      const file = Imageinput.files[0];
      if (Imageinput.files != null && Imageinput.files[0] != null) {
        this.uploadFile(Imageinput.files[0]);
        this.pushImageToEditor();
      }
    });

    // Imageinput.click();
  }

  pushImageToEditor() {
    const range = this.quill.getSelection(true);
    const index = range.index + range.length;
    this.quill.insertEmbed(range.index, 'image', this.imageUrl);
  }
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user')) as User;
    const role = this.user ? this.user.role : 'a';
    if (role === 'a') {
      // this.countries = this.countries.filter(c => c.id === this.user.country_id);
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
      console.log(this.email);
      if (this.id > 0) {
        this.sprintService.getSprint(this.email, this.id).then(sprint => {
          console.log("edit sprint =>",sprint);

          this.model = sprint


          console.log(this.model);
          this.allowed = this.model.allowed_worker_ids;
          this.disallowed = this.model.disallowed_worker_ids;
          if (this.model.schedulings !== null && this.model.schedulings !== undefined) {
            this.schedule = true;
            if (this.model.schedulings !== undefined && this.model.schedulings !== null && this.model.schedulings.length > 0) {
              this.from = this.model.schedulings[0].starts_at;
              this.to = this.model.schedulings[0].finishes_at;
            }
          }
          this.buildBreadCrumb();

          this.loading = false;

        });
      } else {
        this.loading = false;
      }
    });
  }
  onSubmit() { this.submitted = true; }

  getURL(name) {
    return this.uploadUrl + this.model.name.replace(/\s/g, '') + name;
  }
  filterAccounts(search) {
    const p = new Page();
    p.pageNumber = 0;
    p.totalElements = 100;
    p.totalPages = 1;
    p.size = 100;
    this.accountService.getResult(p, this.email).then(accounts => this.accounts = accounts.data);
  }
  buildBreadCrumb() {
    // tslint:disable-next-line:comment-format
    //Sprints? Sprint()>Task Forms ()
    this.crumbs = [];
    this.crumbs.push(new BreadCrumb('Home', '/'));

    this.crumbs.push(new BreadCrumb('Sprints', '/sprints'));
    this.crumbs.push(new BreadCrumb(this.model.name ? this.model.name : 'Sprint', ''));

  }
  translate() {
    this.doTranslate(this.model.name).then((d) => { this.model.namear = d.toString(); });
    this.doTranslate(this.model.description).then((d) => { this.model.descriptionar = d.toString(); });
    this.doTranslate(this.model.short_description).then((d) => { this.model.short_description_ar = d.toString(); });

  }
  doTranslate(key) {
    return new Promise((resolve, reject) => {

      this._google.translate(this.googleObj, key).subscribe(
        (res: any) => {
          resolve(res.data.translations[0].translatedText);
        },
        err => {
          console.log(err);
          reject(err);

        }
      );
    });
  }
  ////////////////////////////////////////////////////////////
  onUploadOutput(output: UploadOutput, c: string): void {
    
    switch (output.type) {
      case 'allAddedToQueue':
        const file = (c === 'icon') ? this.iconFile : this.backroundFile;

        let name = file ? file.name : (new Date()).getTime() + '.jpeg';
        const reader = new FileReader();
        name = this.model.name.replace(/\s/g, '') + name;

        this.getBase64fromFile(file.nativeFile).then((d: base64Response) => {
          const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
          headers.set('Accept', 'application/json');
          // tslint:disable-next-line:max-line-length
          if (c === 'icon') { this.model.iconurl = '../../assets/images/loading.gif'; } else { this.model.backgroundurl = '../../assets/images/loading.gif'; }
          this.http.post(this.uploadUrl + name, d.body, { headers })
            .subscribe(
              (data: S3Response) => {
                if (c === 'icon') { this.model.iconurl = data.Location; } else { this.model.backgroundurl = data.Location; }
                this.toastr.success('File Uploaded');
              },
              error => { console.log('Error  in uoloaing file: ' + error); }
            );

        });
        break;
      case 'addedToQueue':
        if (typeof output.file !== 'undefined') {
          if (c === 'icon') { this.iconFile = output.file; } else { this.backroundFile = output.file; }
        }
        break;
      case 'uploading':
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex((file) => typeof output.file !== 'undefined' && file.id === output.file.id);
          this.files[index] = output.file;
        }
        break;
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter((file: UploadFile) => file !== output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        console.log(output.file.response.Location);
        // tslint:disable-next-line:max-line-length
        if (c === 'icon') { this.model.iconurl = output.file.response.Location; } else { this.model.backgroundurl = output.file.response.Location; }
        // The file is downloaded
        ////////////////////////////////////////////////////////////////////////
        if ((this.model.files === null || this.model.files.length === 0) ||
          this.model.files.find(f => f.upload_type === (c === 'icon' ? 0 : 1)) === undefined) {
          this.model.files.push({
            id: null, created_at: new Date(), uploadable_type: 'Project', uploadable_id: this.model.id,
            upload_type: (c === 'icon' ? 0 : 1), url: output.file.response.Location, is_deleted: false, updated_at: new Date()
          });
        } else {
          this.model.files.find(f => f.upload_type === (c === 'icon' ? 0 : 1)).url = output.file.response.Location;
        }
        break;
    }
  }
  uploadFile(file) {
    const reader = new FileReader();
    const name = this.model.name.replace(/\s/g, '') + (new Date().getMilliseconds());

    this.getBase64fromFile(file.nativeFile).then((d: base64Response) => {
      const headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
      headers.set('Accept', 'application/json');
      this.http.post(this.uploadUrl + name, d.body, { headers })
        .subscribe(
          (data: S3Response) => {
            this.imageUrl = data.Location;
            this.toastr.success('File Uploaded');
          },
          error => { console.log('Error  in uoloaing file: ' + error); }
        );

    });
  }
  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadAll',
      url: this.uploadUrl,
      method: 'POST',
      data: { name: 'bar.jpeg' }
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }

  getBase64fromFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log(`getBase64fromFile success.`);
        const spliced = reader.result.toString().split(',');
        const header = spliced[0];
        spliced.shift();
        resolve({
          header: header,
          body: spliced.join('')
        });
      };
      reader.onerror = (err) => {
        console.log(`getBase64fromFile failed.`);
        reject(err);
      };
    });
  }
  /////////////////////////////////////////////////////////////////
  back() {
    if(this.model.id == null){
      this.router.navigate(['sprints']);
    }
    else{
      this.router.navigate(['sprints', this.model.id]);
    }

  }
  postForm() {

    if (this.model.name == "") {
      this.toastr.warning("Please insert the English Name");
      return false;
    }

    if (this.model.namear == "") {
      this.toastr.warning("Please insert the Arabic Name");
      return false;
    }

    if (this.model.system_name == "") {
      this.toastr.warning("Please insert the System Name");
      return false;
    }

    if (this.model.customer_name == "") {
      this.toastr.warning("Please insert the Customer Name");
      return false;
    }

    if (this.model.max_submission_count <= 0 || this.model.max_submission_count == null || !this.model.max_submission_count) {
      this.toastr.warning("Quota must be greater than 0");
      return false;
    }

    if (this.model.priority <= 0 || this.model.priority == null || !this.model.priority) {
      this.toastr.warning("Priority must be greater than 0");
      return false;
    }

    if (this.model.max_submission_count_per_worker <= 0 || this.model.max_submission_count_per_worker == null || !this.model.max_submission_count_per_worker) {
      this.toastr.warning("Worker Quota must be greater than 0");
      return false;
    }

    let bolv = false;
    let messageError = "";
    if (this.allowed !== null && this.allowed.length > 0) {
      console.log(this.allowed)
      this.model.allowed_worker_ids = this.allowed.toString().split(',').map(Number);
      this.model.allowed_worker_ids.forEach(elementAllowd => {
        if (elementAllowd <= 0) {
          bolv = true;
          messageError = "Allowd Worker Ids should not be a negative and not be equal to zero";

        }
      });
    }
    else {
      this.model.allowed_worker_ids = [];
    }


    // tslint:disable-next-line:max-line-length
    if (this.disallowed !== null && this.disallowed.length > 0) {
      this.model.disallowed_worker_ids = this.disallowed.toString().split(',').map(Number);
      this.model.disallowed_worker_ids.forEach(elementDisAllowd => {
        if (elementDisAllowd <= 0) {
          bolv = true;
          messageError = "disallowd Worker Ids should not be a negative and not be equal to zero";

        }
      });
    }
    else {
      this.model.disallowed_worker_ids = [];
    }

    if (bolv) {
      this.toastr.warning(messageError);
      return false;
    }


    if (this.from !== null) {
      if (this.model.schedulings === null || this.model.schedulings.length === 0) {
        this.model.schedulings.push(
          {
            id: null, created_at: new Date(), updated_at: new Date(), starts_at: this.from, finishes_at: this.to, available_on_friday: true,
            available_on_monday: true, available_on_tuesday: true,
            available_on_sunday: true, available_on_saturday: true, available_on_thursday: true,
            available_on_wednesday: true
          });
      } else {
        this.model.schedulings[0].starts_at = this.from;
        this.model.schedulings[0].finishes_at = this.to;
      }


      //add a new scheduling

    }
    if (!this.model.files) { this.model.files = []; }

    if (this.model.backgroundurl !== null && this.model.backgroundurl !== undefined) {
      const file: Upload = this.model.files.find(f => f.upload_type === 1);
      if (file) { file.url = this.model.backgroundurl; } else { this.model.files.push({
        id: null, created_at: new Date(), updated_at: new Date(), upload_type: 1, uploadable_id: this.model.id
        , uploadable_type: 'Project', url: this.model.backgroundurl, is_deleted: false
      });
      }
    }
    if (this.model.iconurl !== null && this.model.iconurl !== undefined) {
      const file: Upload = this.model.files.find(f => f.upload_type === 0);
      if (file) { this.model.files.find(f => f.upload_type === 0).url = this.model.iconurl; } else { this.model.files.push({
        id: null, created_at: new Date(), updated_at: new Date(), upload_type: 0, uploadable_id: this.model.id
        , uploadable_type: 'Project', url: this.model.iconurl, is_deleted: false
      });
      }
    }
    const targeting: Targeting = {
      gender: this.gender, os: this.os, updated_at: new Date(), created_at: new Date(),
      id: null, project_id: this.model.id, min: this.minValue, max: this.maxValue
    };
    this.sprintService.saveProject(this.model).then(data => {
      this.model = data;
      if (this.model.id !== undefined && this.model.id !== null) {
        targeting.project_id = this.model.id;
        // tslint:disable-next-line:max-line-length
        this.sprintService.saveTargeting(targeting).then(data => console.log('Saved Targeting')).catch(err => console.log('targeting was not saved' + JSON.stringify(err)));
        this.router.navigate(['/sprints', this.model.id]);
      }

      this.toastr.success(this.model.name + ' was saved successfully!!!');

    }).catch(err => {
      this.toastr.success('Error in saving Sprint: ' + JSON.stringify(err));
      console.log(err);
    });
  } // end post form

  onDate(event){
    this.endDate = event;
    console.log("ENDDATE:" + this.endDate);
    console.log("STARTDATE:" + this.startDate);
  }


}
