import { Injectable } from '@angular/core';
import  { API } from 'aws-amplify';
import aws_exports from './../../aws-exports';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Tag } from '../model/tag';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  constructor(public loader: LoadingBarService, public toastr: ToastrService) { }
  public getTags(): Promise<any> {
    // const apiName = 'bahrain';
    const apiName = 'api_node_2';
    const path = `tags`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();
      API.get(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          let data = response.data;
          if (data.response_code == 200) {
            resolve(data.data);
          }
          else
          {
            return this.toastr.error(data.message);
          }
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });

    });
    return promise;
  }
  public getQuestionByTag(tags,text): Promise<any> {
    const apiName = 'geo';
    const path = `questions/bytags?tags=${tags}${text? '&text='+ text: ''}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();
      API.get(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          resolve(response.data);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });

    });
    return promise;
  }
  public saveTag(tag: Tag) {
    // const apiName = 'bahrain';
    const apiName = 'api_node_2';
    const path = `tags`;
    const myInit = {
      body: tag,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      this.loader.start();
      API.post(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          resolve('OK');
          this.loader.complete();
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
}
