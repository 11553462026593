import { UserService } from './../services/user.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { Page} from '../model/page';
import { AccountItem } from '../model/accountItem';
import { AccountService } from '../services/accountService';
import { CsvModule } from '@ctrl/ngx-csv';import { ToastrService } from 'ngx-toastr';

import 'rxjs/add/operator/delay';
import { FormControl, Validators } from '@angular/forms';
import { User } from '../model/user';
@Component({
  selector: 'app-accounts',
   templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {
  page = new Page();
  expanded: any = {};
  @ViewChild('myTable') table: any;
  id: number = null;
  country_id:number =206;
  accountid: number = 0;
  name: string ='';
  rows = [];
  temp=[];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  search = null;
  loading: any = true;
  account: AccountItem= new AccountItem(0, '', 206, null, null,  new Date(), new Date(), false);
  user: User =  new User(0, '', '', '', false, 'en', new Date(), new Date(), '', 206);
  constructor(private accountService: AccountService, private toastr: ToastrService, private userService: UserService) {
    this.page.pageNumber = 0;
    this.page.size = 200;
  }

  ngOnInit() {
       this.setPage({ offset: 0, size:200 });

  } // end on init
  filter() {
    this.loading = true;
    this.accountService.getResult(this.page, this.search).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.temp = pagedData.data;
      this.loading = false;
    });
  }
  public GetAccount(id){
    if(id === 0) this.account = new AccountItem(0, '', 206, null, null,  new Date(), new Date(), false);
    else this.account = this.rows.find(a=>a.id === id);
    

  }
  public GetCustomer(accountid, id){
    
    this.accountid = accountid;
    if(id === 0) this.user = new User(0, '', '', '', false, 'en', new Date(), new Date(), '', 206);
    else this.user = this.rows.find(a=>a.id === accountid).customers.find(c=>c.id === id);

  }
  postForm() {
    
    let ids = [];
    let account = new AccountItem(this.account.id, this.account.name, this.account.country_id, null, 1 , new Date(), new Date(), false);
    this.accountService.saveAccount(account).then(d=>{
      this.toastr.success('Accounts Saved Successfully!');
      this.filter();
    }).catch((err)=>{
      console.log('Error in saving account:'+JSON.stringify(err));
    });

  }
  postCustomerForm() {
    let ids = [];
    this.user.account_id = this.accountid;
    this.accountService.saveCustomer(this.user).then(d=>{
      this.toastr.success('Accounts Saved Successfully!');
      this.filter();
    }).catch((err)=>{
      console.log('Error in saving  customer record: User with specified email exists already!');
    });

  }
  toggleUser(email, condition, status)
  {
    this.userService.toggleUser(email, false, condition, status).then(data=>
    {
      this.toastr.success(`User was ${condition === 1? 'blocked':'unblocked'} successfully!`);
      //this.users.filter(u=>u.email === email).is_blocked = condition ===1;
      this.filter();
    }).catch(e=> this.toastr.error('Operation was not completed'));

  }

  toggleExpandRow(row) {
    
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
  
  setPage(pageInfo) {
    
    this.page.size = pageInfo.size;
    this.page.pageNumber = pageInfo.offset;
    this.accountService.getResult(this.page, this.search).then(pagedData => {
      this.page = pagedData.page;
      this.rows = pagedData.data;
      this.temp = pagedData.data;
      this.loading = false;
      this.loadingIndicator = false;
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    // filter our data
    const temp = this.temp.filter(function(d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;

  }
}
