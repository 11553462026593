import { ProjectService } from './../services/projectService';
import { WorkerService } from './../services/workerService';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../auth/auth.service';
import { UserService } from '../services/user.service';

declare var window: any;
declare var FB: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loading = false;
  showConfirmation: boolean = false;
  loginText: string = 'Login';
  message = '';
  showPassword: boolean = false;
  email = '';
  public loginForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private projectService: ProjectService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initForm();
    //this.initFaceBook();
  }


  initForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      code: ['']
    });
  }
  forgotPassword() {
    this.loading = true;
    this.auth.forgotPassword(this.email)
      .then(d => {
        this.loading = false;
        console.log('forgot password triggered.. now I should show password dialog' + d);
        this.loginText = 'Set Password';
        this.message = 'A new password is required. Confirmation code sent to your mobile number';
        this.showConfirmation = true;
      })
      .catch(errr => console.log('error in forgot password: ' + errr));
  }
  onSubmitLogin(value: any) {
    console.log(value)
    
    const code = value.code;
    const email = value.email,
      password = value.password;
    if (email == '' || password == '') {
      this.message = 'Invalid Email and Password';
      return;
    }
    if (this.showConfirmation) {
      if (code == '') {
        this.message = 'Invalid Code';
        return;
      }
      this.auth.changePassword(email, code, password).
        then(data => {
          console.log(data);
          this.auth.signIn(email, password).subscribe(
            result => {
              this.userService.getUser(email).then(user => {
                console.log('retrieved agent' + JSON.stringify(user));
                this.loading = false;
                this.router.navigate(['/']);

              });

            });//end sign in
        }).catch(err => console.log(err));
    } 
    else {
      this.loading = true;
      this.auth.signIn(email, password).subscribe(
        result => {
          //console.log(result);
          this.userService.getUser(email).then(data => {
            localStorage.setItem("admin_id",data.id);
            console.log('retrieved agent' + JSON.stringify(data));
            this.loading = false;
            this.router.navigate(['/']);

          },error =>{
            console.log(error)
          });

        },
        err => {
          this.loading = false;
          console.log(err.code);
          if (err.code === 'UserNotConfirmedException') {
            console.log('The user is not confirmed Yet');
          } else if (err.code === 'NotAuthorizedException') {
            this.message = 'Invalid Username or Password.';
            // The error happens when the incorrect password is provided
          }
          else if (err.code === 'PasswordResetRequiredException' || err.code === 'NotAuthorizedException') {
            this.forgotPassword();
          } else if (err.code === 'UserNotFoundException') {
            this.message = 'User does not exist!';
          } else {
            console.log(err);
          }
        }
      );
    }
  }  private initFaceBook() {
    (window as any).fbAsyncInit = function () {
      FB.init({
        appId: '269440380340389',
        cookie: true,
        xfbml: true,
        version: 'v3.2'
      });
      console.log('done init FB');
      FB.AppEvents.logPageView();
    };
    (function (d, s, id) {
      console.log('id is', id);
      let js,
        // tslint:disable-next-line:prefer-const
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
      console.log('donwloaded');
    })(document, 'script', 'facebook-jssdk');
  }

  loginWithFacebook() {
    const url =
      // tslint:disable-next-line:max-line-length
      'https://frontendportal.auth.Inisghts Clouds.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://localhost:4200&response_type=CODE&client_id=6rkscc08nrb2puv0690hgjhcnm&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile';
    console.log('will redirect to: ' + url);
    window.location.assign(url);
  }
  public socialSignIn(socialPlatform: string) {
    const auth = this.auth;
    console.log('submit login to facebook');
    FB.login(response => {
      console.log('submitLogin', response);
      if (response.authResponse) {
        console.log('Logged in yaaay' + JSON.stringify(response));
        FB.api('/' + response.authResponse.userID + '/', {
          fields: 'id,name,first_name,middle_name,last_name,' +
            'email,birthday,about,address,gender,hometown,link,location,education,work,relationship_status,picture'
        }, function (profile) {
          console.log('Good to see you, ' + profile.name + '. Full Profile' + JSON.stringify(profile));
          const user = profile;
          auth.SignInFederated(response.authResponse.accessToken, response.authResponse.expiresIn, user).subscribe(
            result => {
              console.log(JSON.stringify(result));
            },
            error => {
              console.log(error);
            }
          );
        });
      } else {
        console.log('User login failed');
      }
    }, {
      scope: 'public_profile,email',
      return_scopes: true
    });
  }
}
