import { Form, Task, Question, Reward, Targeting } from './../model/sprint';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PagedData } from '../model/paged-data';
// import { ProjectItem } from '../model/projectitem';
import { ProjectItem } from '../model/ProjectItem';
import { Convert, Sprint } from "../model/sprint";
import { Page } from '../model/page';
import API from '@aws-amplify/api';
import { AuthService } from '../auth/auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ProjectService {
  constructor(private auth: AuthService, public loader: LoadingBarService, public toastr: ToastrService) {}
  /**
   * A method that mocks a paged server response
   * @param page The selected page
   * @returns {any} An observable containing the employee data
   */
  public getResults(page: Page, search?: any): Observable<PagedData<ProjectItem>> {
    return of(this.getPagedData(page, search));
  }

  public getResult(page: Page, email: string, search: string, country: number, account: number, status: number): Promise<any> {
    const pagedData = new PagedData<ProjectItem>();
    const apiName = 'api_node_1';
    let offset = page.pageNumber * ( page.size === undefined ||page.size === NaN ? 30 :page.size );
    const path = `sprints?email=${email}${search ? '&search=' + search : ''}${country ? '&country=' + country : ''}${status ? '&status=' + status : ''}${country ? '&country=' + country : ''}&limit=${page.size}&offset=${offset}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
        API.get(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          page.totalElements = data.count;
          page.totalPages = page.totalElements / page.size;
          const start = page.pageNumber * page.size;
          const end = Math.min((start + page.size), page.totalElements);
          pagedData.page = page;
          pagedData.data = data.data;
          this.loader.complete();
          resolve(pagedData);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });
    }); // end promoise
    return promise;
  } // end getresult


  private getPagedData(page: Page, search): PagedData<ProjectItem> {
    const pagedData = new PagedData<ProjectItem>();
    const apiName = 'api_node_1';
    const path = `sprints?email=${search}&limit=${page.size}&offsert=${page.pageNumber * page.size}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();

    API.get(apiName, path, myInit)
      .then(response => {
        this.loader.complete();

        const data = response.data;
        page.totalElements = data.count;
        page.totalPages = page.totalElements / page.size;
        const start = page.pageNumber * page.size;
        const end = Math.min((start + page.size), page.totalElements);
        pagedData.data = data.data;
      })
      .catch(error => {
        console.log('Error in invoiking API:' + error);
      });
    pagedData.page = page;
    return pagedData;
  }

  public getSprint(email: string, id: number): Promise<any> {
    
    let sprint: Sprint = null;
    const apiName = 'api_node_1';
    const path = `sprint?email=${email}&id=${id}`;
    const myInit = {
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {

      API.get(apiName, path, myInit)
        .then(response => {
          this.loader.complete();

          let s = response.data;
          sprint = Convert.toSprint(JSON.stringify(s));
          
          resolve(sprint);
        })
        .catch(error => {
          console.log('Error in invoiking API:' + error);
          reject(error);
        });

    });
    return promise;
  }

  public saveProject(sprint: Sprint): Promise<any> {
    const apiName = 'api_node_2';
    const path = `sprint`;
    const myInit = {
      body: sprint,
      headers: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          this.loader.complete();

          const data = response;
          resolve(data);
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }

  public saveTargeting(target: Targeting): Promise<any> {
    const apiName = 'api_node_1';
    const path = `sprint/targeting`;
    const myInit = {
      body: target,
      headers: {}
    };
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {

          const data = response;
          resolve(data);
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }

  public saveForm(model: Form) {
    const apiName = 'api_node_2';
    const path = 'form';
    const myInit = {
      body: model,
      headers: {}
    };
    this.loader.start();

    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          this.loader.complete();

          const data = response;
          resolve(data);
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }

  public saveQuestion(model: Question) {
    const apiName = 'api_node_2';
    const path = 'question';
    const myInit = {
      body: model,
      headers: {}//
    };
    this.loader.start();

    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          this.loader.complete();

          const data = response;
          if (data.response_code == 200) {
            resolve(data.data);
          }
          else
          {
            return this.toastr.error(data.message);
          }
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }

  public saveTask(model: Task) {
    const apiName = 'api_node_2';
    const path = `task`;
    const myInit = {
      body: model,
      headers: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          const data = response.data;
          this.loader.complete();

          resolve('OK');
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }

  public saveReward(model: Reward) {
    const apiName = 'api_node_2';
    const path = `reward`;
    const myInit = {
      body: model,
      headers: {}
    };
    this.loader.start();
    const promise = new Promise((resolve, reject) => {
      API.post(apiName, path, myInit)
        .then(response => {
          this.loader.complete();
          const data = response.data;
          resolve('OK');
        })
        .catch(error => {
          console.log(error.response);
          reject(error);
        });
    }); // end promoise
    return promise;
  }
}
