import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CsvModule } from '@ctrl/ngx-csv';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill'
import { Ng5SliderModule } from 'ng5-slider';
import { AgmCoreModule } from '@agm/core';
import { NgxSortableModule } from 'ngx-sortable'
import {DatePipe} from '@angular/common';
import { SafePipe} from '../../safe.pipe';
// import { PaymentRequestsComponent } from './components/paymentrequests/paymentrequests.component';
import { PaymentRequestsComponent } from './components/paymentrequests/paymentrequests.component';
import { PaymentRoutingModule } from './payment-routing.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgxSelectModule,
    NgxUploaderModule,
    CsvModule,
    QuillModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Ng5SliderModule,
    AmplifyAngularModule,
    TranslateModule,
    NgxSortableModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDP8ctBV6hZ-3zSmr7enXzQTHn5jFd_guc',
      libraries: [ 'drawing']
    }),
    PaymentRoutingModule
  ],
  declarations: [PaymentRequestsComponent],
    providers: [
      AmplifyService,
      // AuthService,
      // AuthGuard,
      // WorkerService,
      // AccountService,
      // ProjectService,
      // PaymentRequestService,
      // SubmissionService,
      DatePipe,
      // TransactionService, MessagingSevice, GoogleService,
      // { provide: TOASTR_TOKEN, useValue: toastr },
      // { provide: JQ_TOKEN, useValue: jQuery }
    ],
  exports: [PaymentRequestsComponent]
})
export class PaymentModule { }
