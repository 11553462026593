import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CsvModule } from '@ctrl/ngx-csv';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill'
import { Ng5SliderModule } from 'ng5-slider';
import { SprintsformRoutingModule } from './sprints-routing.module';
import { AgmCoreModule } from '@agm/core';
import { NgxSortableModule } from 'ngx-sortable'
import {DatePipe} from '@angular/common';
import { SafePipe} from '../../safe.pipe';
import { SprintsformComponent } from './sprints.component';
import { SprintDetailComponent } from './components/sprint-detail/sprint-detail.component';
import { SprintListComponent } from './components/sprint-list/sprint-list.component';
import { SprintEditComponent } from './components/sprint-edit/sprint-edit.component';
import { SubmissionComponent } from './components/submissionFile/submission/submission.component';
import { SubmissionsRequestComponent } from './components/submissionFile/submissions-request/submissionsrequest.component';
import { TasksComponent } from './components/tasks/task-list/task-list.component';
import { TaskformsComponent } from './components/taskforms/taskform-list/taskform-list.component';
import { RewardsComponent } from './components/reward/rewards/rewards.component';
import { ReportsComponent } from '../../reports/reports.component';
import { TaskEditComponent } from './components/tasks/task-edit/task-edit.component';
import { TaskFormComponent } from './components/taskforms/taskform-detail/taskform-detail.component';
import { ReportComponent } from '../../report/report.component';
import { QuestionComponent } from './components/question/question.component';
import { SubmissionTellyComponent } from './components/submissionFile/submission-telly/submission-telly.component';
import { MultimediaModelComponent } from './components/submissionFile/submission/multimedia-model/multimedia-model.component';
import { TagComponent } from './components/tag/tag.component';
import { OptionsTagComponent } from './components/question/options-tag/options-tag.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
	NgbModule,
    CommonModule,
    SprintsformRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxDatatableModule,
    NgxSelectModule,
    NgxUploaderModule,
    CsvModule,
    QuillModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Ng5SliderModule,
    AmplifyAngularModule,
    TranslateModule,
    NgxSortableModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDP8ctBV6hZ-3zSmr7enXzQTHn5jFd_guc',
      libraries: [ 'drawing']
    }),
  ],
  declarations: [
    SprintsformComponent,
     SprintDetailComponent, SprintListComponent, SprintEditComponent,
     SubmissionComponent,
     SubmissionsRequestComponent,
     TasksComponent,
     TaskEditComponent,
     TaskFormComponent,
     ReportsComponent,
     ReportComponent,
     TaskformsComponent,
     RewardsComponent,
     QuestionComponent,
     SafePipe,
     SubmissionTellyComponent,
     MultimediaModelComponent,
     TagComponent,
     OptionsTagComponent
    ],
	entryComponents: [MultimediaModelComponent],
    providers: [
      AmplifyService,
      // AuthService,
      // AuthGuard,
      // WorkerService,
      // AccountService,
      // ProjectService,
      // PaymentRequestService,
      // SubmissionService,
      DatePipe,
      // TransactionService, MessagingSevice, GoogleService,
      // { provide: TOASTR_TOKEN, useValue: toastr },
      // { provide: JQ_TOKEN, useValue: jQuery }
    ],
  exports:[SubmissionComponent,SubmissionsRequestComponent,TasksComponent,
    ReportsComponent,
    TaskformsComponent,
    RewardsComponent,SafePipe,
    TaskEditComponent,
    TaskFormComponent,
    ReportComponent,
    QuestionComponent,]
})
export class SprintsformModule { }
