import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Component({
	selector: "app-options-tag",
	templateUrl: "./options-tag.component.html",
	styleUrls: ["./options-tag.component.css"],
})
export class OptionsTagComponent implements OnInit {

	questionOption;
	sprintTagList;

	@Output("TagOptionSaved") valueChange: EventEmitter<any> =
		new EventEmitter<any>();


	@Input("QuestionOptions")
	set optionModel(value)
	{
		this.questionOption = value;
	}

	@Input("TagList")
	set sprintTag(value)
	{
		this.sprintTagList = value;
	}
	constructor( public toastr: ToastrService) {}

	ngOnInit() {}

	handleTag(event, tagIndex)
	{
		const id = event[0].value;

		console.log(event);
		console.log(id);
		console.log(event[0]);

		console.log(tagIndex);



		let is_tag = false;
		this.questionOption.forEach((elementOpt, index) => {
			console.log(elementOpt.tag_id, id);
			console.log(index, tagIndex);

			if (elementOpt.tag_id == id && index != tagIndex ) {
				is_tag = true;
			}
		});

		if (is_tag) {
			event[0].value = null;
			event[0].text='Select Tag';
			console.log(this.questionOption[tagIndex]);
			this.toastr.error("Tag selection must be unique");
		}
		this.valueChange.emit(this.questionOption)
		return true;
	}
}
